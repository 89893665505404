import "./components/js-form-validator";
import validatorOptions from "./components/validatorOptions";

(function () {
               const contactForm = document.getElementById('newsletterForm');
               const submitButtons = contactForm.querySelectorAll(
                 '.form__submitButton'
               );
               const validator = new Validator(
                 contactForm,
                 function (err, res) {
                   return res;
                 },
                 validatorOptions
               );

               for (const element of contactForm.elements) {
                 element.addEventListener('change', function () {
                   setTimeout(function () {
                     if (validator.validate()) {
                       for (const button of submitButtons)
                         button.disabled = false;
                     } else {
                       for (const button of submitButtons)
                         button.disabled = true;
                     }
                   }, 50);
                 });

                 element.addEventListener('keydown', function () {
                   setTimeout(function () {
                     if (validator.validate()) {
                       for (const button of submitButtons)
                         button.disabled = false;
                     }
                   }, 50);
                 });
               }

             })();