const validatorOptions = {
  locale: 'pl',
    errorClassName: 'form__error',
  messages: {
    pl: {
      required: {
        empty: 'Pole jest wymagane',
        incorrect: 'Nieprawidłowa wartość',
      },
      notzero: {
        empty: 'Please make a selection',
        incorrect: 'Incorrect value',
      },
      integer: {
        empty: 'Enter an integer value',
        incorrect: 'Incorrect integer value',
      },
      float: {
        empty: 'Enter an float number',
        incorrect: 'Incorrect float',
      },
      min: { empty: 'Enter more', incorrect: 'Enter more' },
      max: { empty: 'Enter less', incorrect: 'Enter less' },
      between: {
        empty: 'Enter the between {0}-{1}',
        incorrect: 'Enter the between {0}-{1}',
      },
      name: {
        empty: 'Proszę podać imię',
        incorrect: 'Niepoprawne imię',
      },
      lastname: {
        empty: 'Proszę podać nazwisko',
        incorrect: 'Niepoprawne nazwisko',
      },
      phone: {
        empty: 'Proszę podać numer telefonu',
        incorrect: 'Niepoprawny numer telefonu',
      },
      email: {
        empty: 'Proszę podać adres email',
        incorrect: 'Niepoprawny adres email',
      },
      length: {
        empty: 'Please, Enter a minimum of {0} characters and a maximum of {1}',
        incorrect:
          'Incorrect. Enter a minimum of {0} characters and a maximum of {1}',
      },
      minlength: {
        empty: 'Please, enter at least {0} characters',
        incorrect: 'You have entered less than {0} characters',
      },
      maxlength: {
        empty: 'Please, enter at maximum {0} characters',
        incorrect: 'You have entered more than {0} characters',
      },
      maxfilesize: {
        empty: 'The size of one or more selected files larger than {0} {1}',
        incorrect: 'The size of one or more selected files larger than {0} {1}',
      },
      fileextension: {
        empty: 'Select file',
        incorrect: 'One or more files have an invalid type',
      },
    },
  },
};

export default validatorOptions;